<template>
  <Dialog
    v-if="true"
    :name="name"
    width="728px"
    class-own="dialog-delete-timeslot"
  >
    <template #title>
      <div style="font-size: 16px; font-weight: 400">Удалить тайм слот</div>
    </template>

    <div>
      <div class="dialog-delete-timeslot__message">
        Вы действительно хотите удалить тайм слот?
      </div>

      <div class="df jc-sb">
        <iq-mobile-button
          button-style="padding: 12px; width: 100%; margin-right: 4px"
          text-style="color: #000000; font-weight: 500"
          title="Нет"
          :disabled="isLoading"
          @onClick="handleRemoveTimeslot(false)"
        />
        <iq-mobile-button
          button-style="padding: 12px; width: 100%; margin-left: 4px; background: #F56C6C; border-color: #F56C6C; color: white"
          text-style="color: white; font-weight: 500"
          title="Да"
          :loading="isLoading"
          @onClick="handleRemoveTimeslot(true)"
        />
      </div>
    </div>
  </Dialog>
</template>

<script>
import {
  DELETE_TIMESLOT,
  GET_IS_LOADING_POST_TIMESLOTS,
} from '@/views/manager/store/actions'
import { MANAGER_DELETE_TIMESLOT } from '@/constants/dialogs'
import { mapActions, mapGetters } from 'vuex'
import Dialog from '@/UI/dialog/Dialog'
import IqMobileButton from '@/views/ui/components/mobileButton/IqMobileButton'

export default {
  name: 'DialogDeleteTimeslot',
  components: { IqMobileButton, Dialog },
  data() {
    return { name: MANAGER_DELETE_TIMESLOT }
  },
  computed: {
    ...mapGetters({ isLoading: GET_IS_LOADING_POST_TIMESLOTS }),
    dialogData() {
      return this.getDialog(this.name)?.data
    },
  },
  methods: {
    ...mapActions({ deleteTimeslot: DELETE_TIMESLOT }),
    handleRemoveTimeslot(isRemove = false) {
      if (isRemove) {
        const requestData = {
          timeslot_ids: this.dialogData,
          refund: false,
          nameDialog: this.name,
        }

        this.deleteTimeslot(requestData)
      }
      this.setDialog({ name: this.name })
    },
  },
}
</script>

<style lang="sass">
.manager-delete-timeslot
  .el-dialog__header
    background: #F56C6C!important

.dialog-delete-timeslot
  &__message
    color: #303133
    margin-top: 16px
    margin-bottom: 24px
    font-size: 16px
  .el-button + .el-button
    margin-left: 35px
</style>
