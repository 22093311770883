var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return true
    ? _c(
        "Dialog",
        {
          attrs: {
            name: _vm.name,
            width: "728px",
            "class-own": "dialog-delete-timeslot",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "font-weight": "400",
                        },
                      },
                      [_vm._v("Удалить тайм слот")]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            715941665
          ),
        },
        [
          _c("div", [
            _c("div", { staticClass: "dialog-delete-timeslot__message" }, [
              _vm._v(" Вы действительно хотите удалить тайм слот? "),
            ]),
            _c(
              "div",
              { staticClass: "df jc-sb" },
              [
                _c("iq-mobile-button", {
                  attrs: {
                    "button-style":
                      "padding: 12px; width: 100%; margin-right: 4px",
                    "text-style": "color: #000000; font-weight: 500",
                    title: "Нет",
                    disabled: _vm.isLoading,
                  },
                  on: {
                    onClick: function ($event) {
                      return _vm.handleRemoveTimeslot(false)
                    },
                  },
                }),
                _c("iq-mobile-button", {
                  attrs: {
                    "button-style":
                      "padding: 12px; width: 100%; margin-left: 4px; background: #F56C6C; border-color: #F56C6C; color: white",
                    "text-style": "color: white; font-weight: 500",
                    title: "Да",
                    loading: _vm.isLoading,
                  },
                  on: {
                    onClick: function ($event) {
                      return _vm.handleRemoveTimeslot(true)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }